'use client';

import AOS from 'aos';
import 'aos/dist/aos.css';
import 'photoswipe/dist/photoswipe.css';
import { useEffect, useState } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import '../public/assets/scss/main.scss';

export default function RootLayout({ children }) {
	const [showCursor, setShowCursor] = useState(false);
	useEffect(() => {
		AOS.init({
			duration: 1200,
		});

		if (typeof window !== 'undefined') {
			setShowCursor(true);
		}
	}, []);

	return (
		<html suppressHydrationWarning={true} className="html" lang={'en'}>
			<head>
				<meta charSet="utf-8" />
				<meta name="emotion-insertion-point" content="" />
				<link
					rel="icon"
					type="image/svg+xml"
					href="/images/favicon/favicon.svg"
				/>
				<link
					rel="icon"
					type="image/png"
					href="/images/favicon/favicon.png"
				/>
				<meta name="theme-color" content="#ffffff" />
			</head>
			<body>{children}</body>
		</html>
	);
}
